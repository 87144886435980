import { Component, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import { Notification } from '@/store/interfaces/shared.interfaces'
import { UserService } from '../../Services/User.service'

const sharedStore = namespace('Shared')

@Component
export default class ResetPasswordPage extends Mixins(Validator) {
  @sharedStore.Mutation
  private readonly setNotification: (notification: Notification) => void

  public isLoading = false
  public form = false
  public email = ''
  public login = ''
  public logo = require('../../../assets/logo-login-screen.png')

  public async onReset(): Promise<void> {
    try {
      this.isLoading = true

      await UserService.resetPassword(this.email, this.login)

      this.setNotification({ text: this.$t('resetPasswordPage.successResetPasswordMessage').toString(), timeout: 7000 })

      setTimeout(() => this.$router.push('/login'), 7000)
    } catch (e) {
      if (e.status === 422) {
        this.setNotification({ color: 'error', text: e.data.join(',') })
      } else {
        this.setNotification({ color: 'error', text: this.$t('general.hazerCoreError').toString() })
      }
    } finally {
      this.isLoading = false
    }
  }
}
